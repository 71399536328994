import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN, PATH_BEFORE_LOGIN } from "../config";
//
import {
  Page404,
  // PageOne,
  // PageTwo,
  // PageSix,
  // PageFour,
  // PageFive,
  // PageThree,
  LoginPage,
  Dashboard,
  TransferSlots,
  ResetPasswordPage,
  NewPasswordPage,
  NewLeadSteps,
  OurNetwork,
  AllScheme,
  AddNewSchemePage,
  EditScheme,
  MapSchemeSetting,
  AllBBPSScheme,
  EditBBPSScheme,
  AddNewBBPSScheme,
  MapBBPSScheme,
  ProductManagement,
  AssignVendor,
  MapShortCode,
  VendorManagement,
  MoneyTransferSlots,
  DmtSlots,
  AepsSlots,
  BbpsSlots,
  AddBankAccount,
  AdminFundFlow,
  FundRequest,
  BbpsManagement,
  RollManagement,
  Faqmanagement,
  Updateimages,
  SmsEmailManagement,
  PanVarified,
  DocuSignUpdate,
  NewsNotifications,
  VendorSwitch,
  AccountRecovery,
  BankMaster,
  NewsFlash,
  UploadExternalData,
  EnableDisCategories,
  AEPS,
  Other,
  Mplan,
  AllTransactionRecords,
  FundFlow,
  WalletLadger,
  WaitingAreaForDuplicateTxn,
  HistoricalDataExport,
  DocApiReference,
  SalesManagement,
  Newleads,
  BBPSProducts,
  UserWiseClosingBal,
  AllTransactionRecordsTest,
  FundReports,
  Autocollect,
  EditAutocollect,
  AddNewLoanPage,
  EditLoanScheme,
  AllLoanScheme,
  Services,
  MapLoanScheme,
  EditUserDetail,
  GSTLadger,
  TDSLadger,
  WalletToWallet,
  Dmt2Slots,
  TdsWallet,
  VendorPaymentData,
  CreditCardSlotes,
  GstWallet,
  PayoutSlots,
  Analytics,
  TdsGstReport,
} from "./elements";
import Autocollecttable from "src/sections/Autocollect/Autocollecttable";
import MainWallet from "src/sections/walletmanagement/MainWallet";
import Temporaryreport from "src/sections/reports/Temporaryreport";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={PATH_BEFORE_LOGIN} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "/auth",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "dashboard", element: <Dashboard /> },
        { path: "analytics", element: <Analytics /> },
        { path: "newleads", element: <Newleads /> },
        { path: "newleadsteps", element: <NewLeadSteps /> },
        { path: "edit", element: <EditUserDetail /> },
        { path: "ournetwork", element: <OurNetwork /> },
        {
          path: "scheme",
          children: [
            {
              element: <Navigate to="/auth/scheme/AllScheme" replace />,
              index: true,
            },
            { path: "AllScheme", element: <AllScheme /> },
            { path: "AddNewScheme", element: <AddNewSchemePage /> },
            { path: "EditScheme", element: <EditScheme /> },
            { path: "mapSchemeSetting", element: <MapSchemeSetting /> },
            { path: "AllbbpsScheme", element: <AllBBPSScheme /> },
            { path: "AddNewbbpsScheme", element: <AddNewBBPSScheme /> },
            { path: "EditBBPSScheme", element: <EditBBPSScheme /> },
            { path: "AddNewLoanScheme", element: <AddNewLoanPage /> },
            { path: "EditLoanScheme", element: <EditLoanScheme /> },
            { path: "AllLaonScheme", element: <AllLoanScheme /> },
            { path: "mapbbpsScheme", element: <MapBBPSScheme /> },
            { path: "maploanscheme", element: <MapLoanScheme /> },
          ],
        },
        {
          path: "product",
          children: [
            {
              element: (
                <Navigate to="/auth/product/productmanagement" replace />
              ),
              index: true,
            },
            { path: "productmanagement", element: <ProductManagement /> },
            { path: "assignvendor", element: <AssignVendor /> },
            { path: "mapshortcode", element: <MapShortCode /> },
          ],
        },
        {
          path: "vendor",
          children: [
            {
              element: <Navigate to="/auth/vendor/vendormanagement" replace />,
              index: true,
            },
            { path: "vendormanagement", element: <VendorManagement /> },
            { path: "creditcardslots", element: <CreditCardSlotes /> },
            { path: "moneytransferslots", element: <MoneyTransferSlots /> },
            { path: "vendorpaymentslots", element: <VendorPaymentData /> },
            { path: "dmtslots", element: <DmtSlots /> },
            { path: "dmt2slots", element: <Dmt2Slots /> },
            { path: "mplan", element: <Mplan /> },
            { path: "aepsslots", element: <AepsSlots /> },
            { path: "bbpslots", element: <BbpsSlots /> },
            { path: "transferslots", element: <TransferSlots /> },
            { path: "payoutslots", element: <PayoutSlots /> },
          ],
        },
        {
          path: "fundmanagement",
          children: [
            {
              element: <Navigate to="/auth/fundmanagement/addbank" replace />,
              index: true,
            },
            { path: "addbank", element: <AddBankAccount /> },
            { path: "adminfundflow", element: <AdminFundFlow /> },
            { path: "fundrequest", element: <FundRequest /> },
          ],
        },
        {
          path: "walletmanagement",
          children: [
            {
              element: (
                <Navigate to="/auth/walletmanagement/mainwallet" replace />
              ),
              index: true,
            },
            { path: "mainwallet", element: <MainWallet /> },
            { path: "tdswallet", element: <TdsWallet /> },
            { path: "gstwallet", element: <GstWallet /> },
          ],
        },

        {
          path: "autocollect",
          children: [
            {
              element: <Navigate to="autocollect/listview" replace />,
              index: true,
            },
            { path: "addautocollect", element: <Autocollect /> },
            { path: "editautocollect", element: <EditAutocollect /> },
            { path: "services", element: <Services /> },
            { path: "autocollecttable", element: <Autocollecttable /> },
          ],
        },

        {
          path: "bbpsmanagement",
          children: [
            {
              element: <Navigate to="/bbpsmanagement" replace />,
              index: true,
            },
            { path: "bbpsmanagement", element: <BbpsManagement /> },
            { path: "bbpsProducts", element: <BBPSProducts /> },
          ],
        },

        // { path: "bbpsmanagement", element: <BbpsManagement /> },
        { path: "rollmanagement", element: <RollManagement /> },
        {
          path: "contentmanagement",
          children: [
            {
              element: (
                <Navigate to="/auth/contentmanagement/faqmanagement" replace />
              ),
              index: true,
            },
            { path: "faqmanagement", element: <Faqmanagement /> },
            { path: "updateimage", element: <Updateimages /> },
          ],
        },
        {
          path: "tools",
          children: [
            {
              element: <Navigate to="/auth/tools/smsmanagement" replace />,
              index: true,
            },
            { path: "smsmanagement", element: <SmsEmailManagement /> },
            { path: "panvarified", element: <PanVarified /> },
            { path: "docusign", element: <DocuSignUpdate /> },
            { path: "newsnotifications", element: <NewsNotifications /> },
            { path: "vendorswitch", element: <VendorSwitch /> },
            { path: "accountrecovery", element: <AccountRecovery /> },
            { path: "bankmaster", element: <BankMaster /> },
            { path: "newsflash", element: <NewsFlash /> },
            { path: "uploadexternaldata", element: <UploadExternalData /> },
            { path: "enablediscategories", element: <EnableDisCategories /> },
            { path: "walletToWallet", element: <WalletToWallet /> },

            { path: "aeps", element: <AEPS /> },
            { path: "Other", element: <Other /> },
          ],
        },
        {
          path: "reports",
          children: [
            {
              element: (
                <Navigate to="/auth/reports/alltransactionrecord" replace />
              ),
              index: true,
            },
            {
              path: "alltransactionrecord",
              element: <AllTransactionRecords />,
            },
            { path: "fundflow", element: <FundFlow /> },
            { path: "WalletLadger", element: <WalletLadger /> },
            {
              path: "WaitingAreaForDuplicateTxn",
              element: <WaitingAreaForDuplicateTxn />,
            },
            { path: "HistoricalDataExport", element: <HistoricalDataExport /> },
            { path: "TdsGstReport", element: <TdsGstReport /> },
            { path: "UserWiseClosingBal", element: <UserWiseClosingBal /> },

            { path: "GSTLadger", element: <GSTLadger /> },
            { path: "TDSLadger", element: <TDSLadger /> },
            { path: "temporaryreport", element: <Temporaryreport /> },
          ],
        },
        {
          path: "testreports",
          children: [
            {
              element: (
                <Navigate
                  to="/auth/testreports/alltransactionrecordtest"
                  replace
                />
              ),
              index: true,
            },
            {
              path: "alltransactionrecordtest",
              element: <AllTransactionRecordsTest />,
            },
            {
              path: "fundreports",
              element: <FundReports />,
            },
          ],
        },
        { path: "docapireference", element: <DocApiReference /> },
        { path: "salesmanagement", element: <SalesManagement /> },
        // { path: "one", element: <PageOne /> },
        // { path: "two", element: <PageTwo /> },
        // { path: "three", element: <PageThree /> },
        // {
        //   path: "user",
        //   children: [
        //     {
        //       element: <Navigate to="/dashboard/user/four" replace />,
        //       index: true,
        //     },
        //     { path: "four", element: <PageFour /> },
        //     { path: "five", element: <PageFive /> },
        //     { path: "six", element: <PageSix /> },
        //   ],
        // },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: "404", element: <Page404 /> },
        { path: "resetpassword", element: <ResetPasswordPage /> },
        { path: "newpassword", element: <NewPasswordPage /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
