// @mui
import { useTheme } from "@mui/material/styles";
import {
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// config
import { HEADER, NAV } from "../../../config";
// components
import Logo from "../../../components/logo";
import Iconify from "../../../components/iconify";
import { useSettingsContext } from "../../../components/settings";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import ContactsPopover from "./ContactsPopover";
import NotificationsPopover from "./NotificationsPopover";
import Label from "src/components/label/Label";
import { fCurrency, fIndianCurrency } from "src/utils/formatNumber";
import { useAuthContext } from "src/auth/useAuthContext";
import { useState } from "react";
import MotionModal from "src/components/animate/MotionModal";
import ApiDataLoading from "src/components/CustomFunction/ApiDataLoading";
import { TableNoData } from "src/components/table";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const { Api } = useAuthContext();
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [vendorList, setVendorList] = useState<string>("");
  const [Ambika, setAmbika] = useState<string>("");
  const [RupeeBiz, setRupeeBiz] = useState<string>("");
  const [DecentroIdfc, setDecentroIdfc] = useState<string>("");
  const [DecentroAxis, setDecentroAxis] = useState<string>("");
  const [Plural, setPlural] = useState<string>("");
  const [InstantPay, setInstantPay] = useState<string>("");

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout == "horizontal";

  const isNavMini = themeLayout == "mini";

  const isDesktop = useResponsive("up", "lg");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;
  const isTablet = useResponsive("up", "sm");

  const walletStyle = {
    textTransform: "capitalize",
    borderColor: "primary",
    borderRadius: 8,
    borderWidth: "2px",
    borderStyle: "solid",
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#ffffff",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  };

  function getBalance() {
    openModal();
    getVendor();
    AmbikaVendor();
    RupeeBizVendor();
    DecentroIdfcVendor();
    DecentroAxisVendor();
    PluralVendor();
    InstantPayVendor();
  }

  const getVendor = () => {
    setIsLoading(true);
    let token = localStorage.getItem("token");
    Api(`vendor/getAirtelVendorBalance`, "GET", "", token).then(
      (Response: any) => {
        console.log("======getVendor==response=====>", Response);
        if (Response?.status == 200) {
          if (Response.data.code == 200) {
            setVendorList(Response.data.data);
            setIsLoading(false);
            console.log("======getVendor===data.data 200====>", Response.data);
          } else {
            console.log("======getVendor=======>" + Response);
            setIsLoading(false);
          }
        }
      }
    );
  };

  const AmbikaVendor = () => {
    setIsLoading(true);
    let token = localStorage.getItem("token");
    Api(`vendor/getAmbikaVendorBalance`, "GET", "", token).then(
      (Response: any) => {
        console.log("======getVendor==response=====>", Response);
        if (Response?.status == 200) {
          if (Response.data.code == 200) {
            setAmbika(Response.data.data);
            setIsLoading(false);
            console.log("======getVendor===data.data 200====>", Response.data);
          } else {
            console.log("======getVendor=======>" + Response);
            setIsLoading(false);
          }
        }
      }
    );
  };
  const RupeeBizVendor = () => {
    setIsLoading(true);
    let token = localStorage.getItem("token");
    Api(`vendor/getRupeeBizVendorBalance`, "GET", "", token).then(
      (Response: any) => {
        console.log("======getVendor==response=====>", Response);
        if (Response?.status == 200) {
          if (Response.data.code == 200) {
            setRupeeBiz(Response.data.data);
            setIsLoading(false);
            console.log("======getVendor===data.data 200====>", Response.data);
          } else {
            console.log("======getVendor=======>" + Response);
            setIsLoading(false);
          }
        }
      }
    );
  };

  const DecentroIdfcVendor = () => {
    setIsLoading(true);
    let token = localStorage.getItem("token");
    Api(`vendor/getDecentroIdfcVendorBalance`, "GET", "", token).then(
      (Response: any) => {
        console.log("======getVendor==response=====>", Response);
        if (Response?.status == 200) {
          if (Response.data.code == 200) {
            setDecentroIdfc(Response.data.data);
            setIsLoading(false);
            console.log("======getVendor===data.data 200====>", Response.data);
          } else {
            console.log("======getVendor=======>" + Response);
            setIsLoading(false);
          }
        }
      }
    );
  };
  const DecentroAxisVendor = () => {
    setIsLoading(true);
    let token = localStorage.getItem("token");
    Api(`vendor/getDecentroAxisVednorBalance`, "GET", "", token).then(
      (Response: any) => {
        console.log("======getVendor==response=====>", Response);
        if (Response?.status == 200) {
          if (Response.data.code == 200) {
            setDecentroAxis(Response.data.data);
            setIsLoading(false);
            console.log("======getVendor===data.data 200====>", Response.data);
          } else {
            console.log("======getVendor=======>" + Response);
            setIsLoading(false);
          }
        }
      }
    );
  };
  const PluralVendor = () => {
    setIsLoading(true);
    let token = localStorage.getItem("token");
    Api(`vendor/getPluralVendorBalance`, "GET", "", token).then(
      (Response: any) => {
        console.log("======getVendor==response=====>", Response);
        if (Response?.status == 200) {
          if (Response.data.code == 200) {
            setPlural(Response.data.data);
            setIsLoading(false);
            console.log("======getVendor===data.data 200====>", Response.data);
          } else {
            console.log("======getVendor=======>" + Response);
            setIsLoading(false);
          }
        }
      }
    );
  };

  const InstantPayVendor = () => {
    setIsLoading(true);
    let token = localStorage.getItem("token");
    Api(`vendor/getInstantPayVendorBalance`, "GET", "", token).then(
      (Response: any) => {
        console.log("======getVendor==response=====>", Response);
        if (Response?.status == 200) {
          if (Response.data.code == 200) {
            setInstantPay(Response.data.data);
            setIsLoading(false);
            console.log("======getVendor===data.data 200====>", Response.data);
          } else {
            console.log("======getVendor=======>" + Response);
            setIsLoading(false);
          }
        }
      }
    );
  };

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: "text.primary" }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Searchbar />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {isTablet ? (
          <>
            <Label
              variant="soft"
              color={"warning"}
              sx={walletStyle}
              onClick={getBalance}
            >
              Vendor Wallet Balance
            </Label>
            <MotionModal
              open={isModalOpen}
              onClose={closeModal}
              aria-labelledby="Vendor Wallet Modal"
            >
              {isLoading ? (
                <ApiDataLoading />
              ) : (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Vendor Name</TableCell>
                        <TableCell>Vendor Balance</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Airtel</TableCell>
                        <TableCell>{fCurrency(vendorList)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Ambika</TableCell>
                        <TableCell>{fCurrency(Ambika)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>RupeeBiz</TableCell>
                        <TableCell>{fCurrency(RupeeBiz)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Decentro IDFC</TableCell>
                        <TableCell>{fCurrency(DecentroIdfc)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Decentro Axis</TableCell>
                        <TableCell>{fCurrency(DecentroAxis)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Plural</TableCell>
                        <TableCell>{fCurrency(Plural)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Instant Pay</TableCell>
                        <TableCell>{fCurrency(InstantPay)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <LoadingButton variant="contained" onClick={closeModal}>
                    Close
                  </LoadingButton>
                </TableContainer>
              )}
            </MotionModal>
            <Label variant="soft" color={"warning"} sx={walletStyle}>
              TDS = {fIndianCurrency(user?.TDSWallet) || 0}
            </Label>
            <Label variant="soft" color={"warning"} sx={walletStyle}>
              GST = {fIndianCurrency(user?.GSTWallet) || 0}
            </Label>
            <Label variant="soft" color={"primary"} sx={walletStyle}>
              Main = {fIndianCurrency(user?.main_wallet_amount) || 0}
            </Label>
            <Label variant="soft" color={"warning"} sx={walletStyle}>
              Aeps = {fIndianCurrency(user?.AEPS_wallet_amount) || 0}
            </Label>
            {/* <LanguagePopover /> */}
            <NotificationsPopover />
            <ContactsPopover />
            <AccountPopover />
          </>
        ) : (
          <AccountPopover />
        )}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: "background.default",
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
